<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
            <a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" allow-clear style="width: 180px;">
              <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="影片名称" name="filmName" class="ui-form__item">
            <a-input v-model:value="formState.filmName" placeholder="请输入影片名称"></a-input>
          </a-form-item>

          <a-form-item label="用户信息" name="userInfo" class="ui-form__item">
            <a-input v-model:value="formState.userInfo" placeholder="请输入用户信息"></a-input>
          </a-form-item>

          <a-form-item label="订单号" name="orderNo" class="ui-form__item">
            <a-input v-model:value="formState.orderNo" placeholder="请输入订单号"></a-input>
          </a-form-item>
        </a-row>

        <a-row v-show="showAll">
          <a-form-item label="操作人" name="logUserName" class="ui-form__item">
            <a-input v-model:value="formState.logUserName" placeholder="请输入操作人"></a-input>
          </a-form-item>

          <a-form-item label="退款状态" name="refundStatus" class="ui-form__item">
            <a-select v-model:value="formState.refundStatus" placeholder="请选择退款状态" allow-clear style="width: 195px;">
              <a-select-option :value="0">无需退款</a-select-option>
              <a-select-option :value="1">退款中</a-select-option>
              <a-select-option :value="2">退款成功</a-select-option>
              <a-select-option :value="3">退款失败</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="退款方式" name="refundUserType" class="ui-form__item">
            <a-select v-model:value="formState.refundUserType" placeholder="请选择退款方式" allow-clear style="width: 195px;">
              <a-select-option :value="1">小程序退票</a-select-option>
              <a-select-option :value="2">后台退票</a-select-option>
              <a-select-option :value="3">柜台退票</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="退票时间"  name="createTime" class="ui-form__item">
            <a-range-picker v-model:value="formState.createTime"></a-range-picker>
          </a-form-item>

          <a-form-item label="放映时间"  name="showTime" class="ui-form__item">
            <a-range-picker v-model:value="formState.showTime"></a-range-picker>
          </a-form-item>
        </a-row>

        <a-row>
          <a style="margin-bottom: 10px" @click="showAll = !showAll">
            {{ showAll ? '收起' :'展开' }}
            <Icon v-show="!showAll" icon="DownOutlined"></Icon>
            <Icon v-show="showAll" icon="UpOutlined"></Icon>
          </a>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button v-permission="['reportForms_signature_return_export']" type="link" style="background: rgb(26, 182, 103); color: white;">导 出</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
                style="margin-right: 10px"
                type="primary"
                html-type="submit"
            >搜索</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>

      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                 :scroll="{ x: 2450 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'showStartTime'">
              {{ transDateTime(record.showStartTime) }}
            </template>
            <template v-if="column.key === 'userInfo'">
              <div>用户ID：{{ record.userId || '-' }}</div>
              <div>用户名称：{{ record.userNickname || '-' }}</div>
              <div>用户手机：{{ record.userPhone || '-' }}</div>
            </template>
            <template v-if="column.key === 'refundTime'">
              {{ transDateTime(record.refundTime) }}
            </template>
            <template v-if="column.key === 'refundStatus'">
              <div v-if="record.refundStatus === 0">无需退款</div>
              <div v-if="record.refundStatus === 1">退款中</div>
              <div v-if="record.refundStatus === 2">退款成功</div>
              <div v-if="record.refundStatus === 3">退款失败</div>
            </template>
            <template v-if="column.key === 'refundUserType'">
              <div v-if="record.refundUserType === 1">小程序退票</div>
              <div v-if="record.refundUserType === 2">后台退票</div>
              <div v-if="record.refundUserType === 3">柜台退票</div>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>

  </div>
</template>

<script>
import {
  Icon
} from '@/components/icon/icon.js';
import { getCinemaAllList } from '@/service/modules/cinema.js';
import {getCinemaRefundList} from "../../../../service/modules/report";
export default {
  components: {
    Icon,
  },
  data() {
    return {
      showAll: false,
      showModal: false,
      isEdit: false,
      isSee: false,
      id: 0,
      loading: false,
      modelRef: {},
      formState: {},
      searchData: {},
      cinemaAllList: [],
      columns: [{
        title: '放映日期',
        key: 'showStartTime',
      }, {
        title: '影院名称',
        dataIndex: 'cinemaName',
      }, {
        title: '订单号',
        dataIndex: 'orderNo',
      }, {
        title: '实付金额（元）',
        dataIndex: 'payment',
        width: 150
      }, {
        title: '影片信息',
        dataIndex: 'filmName',
      }, {
        title: '用户信息',
        key: 'userInfo',
      }, {
        title: '退票服务费',
        dataIndex: 'totalServiceFee',
        width: 150
      }, {
        title: '退票时间',
        key: 'refundTime',
      }, {
        title: '退款金额',
        dataIndex: 'refund',
        width: 150
      }, {
        title: '退款状态',
        key: 'refundStatus',
      }, {
        title: '退款方式',
        key: 'refundUserType',
      }, {
        title: '退票人',
        dataIndex: 'createUserName',
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.getData();
    this.getAllCinemaList();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    async getData() {
      if(this.formState.createTime && this.formState.createTime.length === 2) {
        this.searchData.createStartTime = this.moment(this.formState.createTime[0].startOf('day')).unix();
        this.searchData.createEndTime = this.moment(this.formState.createTime[1].endOf('day')).unix();
      }
      if(this.formState.showTime && this.formState.showTime.length === 2) {
        this.searchData.showStartTime = this.moment(this.formState.showTime[0].startOf('day')).unix();
        this.searchData.showEndTime = this.moment(this.formState.showTime[1].endOf('day')).unix();
      }
      delete this.searchData.showTime;
      delete this.searchData.createTime;
      this.loading = true;
      try {
        let ret = await getCinemaRefundList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    async getAllCinemaList() {
      this.cinemaAllList = [];
      let ret = await getCinemaAllList({})
      if (ret.code === 200) {
        this.cinemaAllList = ret.data;
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
